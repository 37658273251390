import { useStaticQuery, graphql } from 'gatsby';
import { NavigationElementsFragment } from '../../fragments/navigation/navigation-elements';

export const useMainNavigation = () => {
	const { prismicNavigation } = useStaticQuery(
		graphql`
			query MainNavigation {
				prismicNavigation(data: { navigation_type: { eq: "Main" } }) {
					...NavigationElementsFragment
				}
			}
		`
	);
	return prismicNavigation.data;
};
