import React from "react"
import styled from "@emotion/styled"

const IconArrowSvg = styled.svg`
  &.right {
    transform: rotate(0);
  }
  &.left {
    transform: rotate(180deg);
  }
  &.top {
    transform: rotate(-90deg);
  }
  &.bottom {
    transform: rotate(90deg);
  }
`

const IconArrow = ({
  bgColor = "#111",
  color = "#fff",
  direction = "right",
  width = 50,
  ariaTitleLanguage = "en",
  ariaTitle = "Arrow icon",
}) => {
  const iconId = `arrow-${direction}`
  const ariaTitleId = `title-${iconId}`

  return (
    <IconArrowSvg
      id={iconId}
      className={direction}
      role="presentation"
      aria-labelledby={ariaTitleId}
      viewBox="0 0 50 50"
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={ariaTitleId} lang={ariaTitleLanguage}>
        {ariaTitle}
      </title>
      <ellipse cx="25" cy="25" rx="25" ry="25" fill={bgColor} />
      <path
        transform="translate(-1, 0)"
        d="M21 13.333L32.667 25 21 36.667"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconArrowSvg>
  )
}

export default IconArrow
