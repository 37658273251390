import React from 'react';
import styled from '@emotion/styled';
import Branding from './branding';
import MainNavigation from './navigation/main-navigation';
import MobileNavigation from './navigation/mobile-navigation';

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Header = () => {
	return (
		<HeaderWrapper>
			<Branding />
			<MainNavigation />
			<MobileNavigation />
		</HeaderWrapper>
	);
};

export default Header;
