import React from 'react';
import { css } from '@emotion/core';
import globalStyles from '../../global-styles';

const MenuItem = ({ tag: Tag = 'li', ...restProps }) => {
	const style = css`
		background-color: ${globalStyles.colors.lightGrey};
		padding: 0.5em;
		text-align: center;
		margin: 0 2em 2em 0;

		.mobile {
			margin: 0 2em 0 0;
		}

		a {
			color: ${globalStyles.colors.black};
			font-size: 1.8rem;
			text-transform: uppercase;
			font-weight: 800;
			line-height: normal;
			cursor: pointer;
		}

		&:hover {
			background-color: ${globalStyles.colors.blue};
			a {
				color: ${globalStyles.colors.white};
			}
		}
	`;

	return <Tag css={style} {...restProps} />;
};

export default MenuItem;
