import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import styled from '@emotion/styled';
import globalStyles from '../../global-styles';
import { useMainNavigation } from '../../hooks/navigation/use-main-navigation';
import MenuItem from './menu-item';

const MainNav = styled.nav`
	display: none;
	flex-grow: 1;

	${globalStyles.mq[1]} {
		display: block;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		${globalStyles.mq[1]} {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
	}
`;

const MainNavigation = () => {
	const { menu_links: menuLinks } = useMainNavigation();

	return (
		<MainNav>
			<ul>
				{menuLinks.map(linkItem => {
					return (
						<MenuItem key={linkItem.slug}>
							<Link
								activeClass="active"
								className={linkItem.slug}
								to={linkItem.slug}
								spy={true}
								smooth={true}
								duration={500}
							>
								{linkItem.label.text}
							</Link>
						</MenuItem>
					);
				})}
			</ul>
		</MainNav>
	);
};

export default MainNavigation;
