import React from "react"
import Header from "./header"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { animateScroll as scroll } from "react-scroll"
import globalStyles from "../global-styles"
import IconArrow from "../components/icons/icon-arrow"

const baseStyles = css`
  html {
    background-color: ${globalStyles.colors.white};
    font-family: ${globalStyles.fonts.base.fontFamily};
    font-size: 62.5%;
    /* Equal to 10px -  If you haven't set the font size anywhere on the page, then it is the browser default, which is often 16px */
    color: ${globalStyles.colors.black};
  }

  a {
    text-decoration: none;
    color: ${globalStyles.colors.blue};
  }

  /* no outline for non-keyboard-inputs elements */
  button:focus,
  select:focus {
    outline: none;
  }
`

const BackToTopButton = styled.button`
  display: block;
  position: fixed;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 50;
  bottom: 4rem;
  right: 3rem;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`

const scrollToTop = () => {
  scroll.scrollToTop()
}

const Layout = ({ children }) => {
  const { Row } = globalStyles.grid

  return (
    <div>
      <Global
        styles={css`
          ${baseStyles};
          ${globalStyles.reset};
          ${globalStyles.fonts.baseFontStyling};
        `}
      />
      <Row>
        <Header />
      </Row>
      <main>{children}</main>
      <BackToTopButton onClick={scrollToTop}>
        <IconArrow width="40" direction="top" />
      </BackToTopButton>
    </div>
  )
}

export default Layout
