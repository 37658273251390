import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Branding = () => {
	const data = useStaticQuery(graphql`
		query {
			prismicBranding {
				data {
					title {
						text
						html
					}
					tagline {
						text
						html
					}
					created_by {
						text
						html
					}
					logo_image {
						localFile {
							childImageSharp {
								fixed(width: 120, height: 50) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div>
			<a href="/">
				<Img fixed={data.prismicBranding.data.logo_image.localFile.childImageSharp.fixed} />
			</a>
		</div>
	);
};

export default Branding;
