import React from "react"

const IconMenu = ({
  width = 24,
  ariaTitleLanguage = "en",
  ariaTitle = "Navigation menu icon",
}) => {
  const iconId = `navigation-menu`
  const ariaTitleId = `title-${iconId}`
  return (
    <svg
      id={iconId}
      role="presentation"
      aria-labelledby={ariaTitleId}
      viewBox="0 0 24 24"
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={ariaTitleId} lang={ariaTitleLanguage}>
        {ariaTitle}
      </title>
      <path
        d="M2 2h20M2 12h20M2 22h20"
        stroke="#050505"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconMenu
