const colors = {
  white: "#FFFFFF",
  lightGrey: "#F2F2F2",
  grey: "#E5E5E5",
  black: "#111111",
  blue: "#089BD9",
  green: "#55AA55",
  darkGreen: "#224C00",
  red: "#BE5F7C",
  errorRed: "#DF0101",
  darkRed: "#4C0017",
}

export default colors
