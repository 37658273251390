import React, { useState } from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import styled from "@emotion/styled"
import { useMainNavigation } from "../../hooks/navigation/use-main-navigation"
import globalStyles from "../../global-styles"
import IconMenu from "../icons/icon-menu"
import IconClose from "../icons/icon-close"
import MenuItem from "./menu-item"

const MobileNavigationWrapper = styled.div`
  position: relative;
  display: block;
`

const SideDrawerContent = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background: ${globalStyles.colors.white};
  box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.26);
  z-index: 100;
  display: flex;
  align-items: center;
  top: 0;
  right: -105%; /*105% because of the box-shadow*/
  transition: right 0.3s ease-out;

  &.open {
    right: 0;
  }

  &.closed {
    right: -105%;
  }
`

const SideDrawerToggler = styled.button`
  position: absolute;
  top: 4.5rem;
  right: 3rem;
  border: none;
  border-collapse: collapse;
  cursor: pointer;
  background-color: transparent;
  z-index: 200;

  ${globalStyles.mq[1]} {
    display: none;
  }
`

const SideDrawerNav = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`

const MobileNavigation = () => {
  const { menu_links: menuLinks } = useMainNavigation()
  const [menuToggleState, setMenuToggleState] = useState(false)

  const toggleMobileNavigation = () => {
    setMenuToggleState(!menuToggleState)
  }

  return (
    <div>
      <SideDrawerToggler type="button" onClick={toggleMobileNavigation}>
        {menuToggleState === false ? <IconMenu /> : <IconClose />}
      </SideDrawerToggler>
      <SideDrawerContent className={`${menuToggleState ? "open" : ""}`}>
        <SideDrawerNav>
          <ul>
            {menuLinks.map(linkItem => {
              return (
                <MenuItem className="mobile" key={linkItem.slug}>
                  <Link
                    activeClass="active"
                    className={linkItem.slug}
                    to={linkItem.slug}
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={toggleMobileNavigation}
                  >
                    {linkItem.label.text}
                  </Link>
                </MenuItem>
              )
            })}
          </ul>
        </SideDrawerNav>
      </SideDrawerContent>
    </div>
  )
}

export default MobileNavigation
