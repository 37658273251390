import styled from '@emotion/styled';
import { css } from '@emotion/core';

const marginBase = '3rem';
const container = {
	maxWidth: '1280px',
	margin: '0 auto'
};

const containerStyle = css`
	max-width: ${container.maxWidth};
	margin: ${container.margin};
`;

const Row = styled.div`
	margin: ${marginBase};
`;

export default { marginBase, container, containerStyle, Row };
